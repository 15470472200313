<template>
  <div class="body-bg">
    <div class="container">
      <van-nav-bar
      title="我的邀请"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>

      <div class="header">
        <div class="header-img">
        </div>
        <p>长按邀请二维码，可保存到相册，使用更方便哦！</p>
      </div>
    </div>
    <div class="qr-box">
      <div class="qr-header">
        <div class="name">{{userInfo.staff_name}}</div>
        <div class="des">极速代办</div>
      </div>
      <div class="qr-code-img" @touchstart="saveQCode" >
        <img :src="userInfo.share_img" />
      </div>
      <div class="qr-tip">
        <p>用微信扫码二维码，加入我们</p>
      </div>
    </div>
    <div class="container">
      <div class="action-bar">
        <div class="button">
          <van-button round block type="info" color="#F5C204" @click="toTeam"
            ><p>我的队伍</p></van-button
          >
        </div>
        <div class="button">
          <van-button round block type="info" plain color="#F5C204" @click="toTeamOrder"
            ><p>队伍订单</p></van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const plus = window.plus;
import UserService from "@/api/user";
export default {
  data() {
    return {
      longClick: 0,
      timeOutEvent: 0,
      userInfo: null,
    };
  },
  async beforeCreate(){
    console.log("生成邀请二维码");
     //得到用户统计信息
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
      };
       const resp1  = await UserService.staffInfo(this,params)

      if (resp1.data.code == 10000) {
        console.log(resp1.data.data)
         this.userInfo = resp1.data.data;
         this.userInfo.share_img = 'http://jsagency.cn/'+resp1.data.data.share_img;
      }
      console.log(this.userInfo)
      
  },
  methods: {
    goBack: function() {
      this.$router.go(-1);
    },
    goHome: function() {
      this.$router.push("/");
    },

    toTeam: function() {
      this.$router.push("/person/invite/team");
    },  
    toTeamOrder: function() {
      this.$router.push("/person/invite/teamOrder");
    },
    saveQCode() {
      var that = this;
      this.longClick = 0;
      this.timeOutEvent = setTimeout(function() {
        that.longClick = 1;
        that.downImg();
      }, 500);
    },
    downImg() {
      if (!window.plus) return;
      plus.gallery.save(
        this.imgUrl,
        function() {
          
        },
        function() {
          
        }
      );
    },
  },
};
</script>

<style scoped>
.body-bg {
  background: url("~@/assets/img/invite-bg.png") no-repeat;
  background-size: 110%;
  background-position: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.container {
  width: 100%;
  background: #fff;
}
.header {
  margin: 30px 24px 10px 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.header .header-img {
  border: 0;
  width: 100%;
  height: 60px;
  background: url("~@/assets/img/invitetips.png") no-repeat;
    background-size: 100% 100%; 
  background-position: center;
}
.header p {
  font-size: 12px;
  color: #333;
}
.qr-box {
  width: 230px;
  height: 286px;
  background: url("~@/assets/img/qr-bg.png") no-repeat;
  background-size: 100%;
  background-position: center;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.qr-box .qr-header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.qr-box .qr-header .name {
  font-size: 16px;
  color: #333333;
}
.qr-box .qr-header .des {
  margin-top: 8px;
  font-size: 14px;
  color: #b3b3b3;
}

.qr-box .qr-code-img {
  margin-top: 10px;
  width: 140px;
  height: 140px;
}
.qr-code-img img {
  border: none;
  width: 100%;
  height: 100%;
}
.qr-box .qr-tip p {
  margin-top: 10px;
  color: #333;
  font-size: 12px;
}
.action-bar {
  margin: 0 24px 10px 24px;
}
.action-bar .button {
  margin-bottom: 20px;
}
.action-bar .button p {
  color: #333;
}
</style>
